body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.box-shadow-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-range-reset {
  appearance: none;
  background: transparent;
}

.input-range-reset::-webkit-slider-thumb {
  appearance: none;
  width: 0;
  height: 0;
}

.input-range-reset::-moz-range-thumb {
  appearance: none;
  border: 0;
  width: 0;
  height: 0;
}

.z-minus-1 {
  z-index: -1;
}

.pointer-events-none {
  pointer-events: none;
}

.tabular-nums {
  font-variant-numeric: tabular-nums;
}

.w-5rem {
  width: 5rem;
}

.grid-justify-start {
  justify-self: start;
}

.force-layer {
  transform: translateZ(0);
}
